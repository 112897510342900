import React from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import media from "@styles/media"
import styled from "styled-components"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Container from "@components/layout/container"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Anchor from "@components/elements/anchor"
import Div from "@components/elements/div"
import backgroundGradient from "@images/pm-standard-gradient.png"
import Span from "@components/elements/span"
import { graphql } from "gatsby"
import Image from "@components/image"
import { Accordion } from "@components/common/accordion"
import Button from "@components/elements/button"
import { UserIcon } from "@components/pages/about/user-icon"
import { Form } from "@components/common/form"
import HubspotForm from "react-hubspot-form"
import { PricingIcons } from "@components/pages/pricing/pricing-icons"

const GradientBackground = styled.img`
  max-width: 2500px;
  width: 2500px;
  position: absolute;
  z-index: -3;
  top: 0;
  left: -400px;
  bottom: 0;
  margin: auto;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );

  ${media.md`
  display: none;
`}
`

const BackgroundContainer = styled.div`
  position: relative;
  height: 0;
  svg {
    z-index: -1;
    position: absolute;
    top: -1000px;
    left: 0;
    right: 0;
    max-width: 1500px;
    margin: 0 auto;
    width: 100%;

    ${media.md`
    display: none;
  `}
  }
`

const CompanyPlanPage = ({ data }) => {
  const users = data.users.edges

  return (
    <Layout
      title={`Business Dashboards and Reporting Software for Everyone - Klipfolio`}
      description={`Klipfolio is the leading platform for self-service BI, dashboards, and reporting. From startups to Fortune 500 teams, Klipfolio provides the power and flexibility you always wanted at an affordable price. Succeed with data analytics today and start for FREE.`}
      fullWidth
      marginless
      hideOverflow
    >
      <Flex center margin="10rem auto 4rem" alignItems="center">
        <Container center text>
          <header>
            <Heading
              fontSize="3rem"
              fontWeight="600"
              lineHeight="130%"
              margin="0 0 2rem"
            >
              The <b>Unlimited Company Plan</b> to launch your data analytics
              strategy
            </Heading>
          </header>
          <Button
            background="linear-gradient(93.42deg, #635BFF 5.29%, #995BFF 96.98%)"
            color="white"
            arrow
            notice={false}
            href="#contact-us"
          >
            Let&apos;s talk
          </Button>
        </Container>
      </Flex>
      <BackgroundContainer>
        <GradientBackground src={backgroundGradient} />
      </BackgroundContainer>
      <Div background="#21264f" padding="4rem 0">
        <Flex gap="1rem" textContainer>
          <Heading as="h2" color="white" margin="0 0 1rem">
            Power and flexibility
          </Heading>
          <Paragraph color="white">
            Getting your data analytics infrastructure and processes up and
            running can be time-consuming and tedious, especially for big teams
            like yours. We’re here to help.
          </Paragraph>
          <Paragraph color="white">
            Our friendly Support Team experts will get you set up with Klipfolio
            onboarding, training, and report building all for just $12K in your
            first year. This includes:
          </Paragraph>
          <Grid
            columns="repeat(3, 1fr)"
            columnsSm="1fr"
            gap="2rem 1rem"
            margin="2rem 0"
          >
            <Div>
              <Flex
                borderRadius="50%"
                height="23px"
                width="23px"
                background="#707efa"
                center
              >
                <Span margin="-3px 0 0 0">✓</Span>
              </Flex>
              <Paragraph margin="1rem 0 0 0" lineHeight="130%" color="white">
                Unlimited users
              </Paragraph>
            </Div>
            <Div>
              <Flex
                borderRadius="50%"
                height="23px"
                width="23px"
                background="#707efa"
                center
              >
                <Span margin="-3px 0 0 0">✓</Span>
              </Flex>
              <Paragraph margin="1rem 0 0 0" lineHeight="130%" color="white">
                Unlimited dashboards
              </Paragraph>
            </Div>
            <Div>
              <Flex
                borderRadius="50%"
                height="23px"
                width="23px"
                background="#707efa"
                center
              >
                <Span margin="-3px 0 0 0">✓</Span>
              </Flex>
              <Paragraph margin="1rem 0 0 0" lineHeight="130%" color="white">
                Unlimited Klips
              </Paragraph>
            </Div>
            <Div>
              <Flex
                borderRadius="50%"
                height="23px"
                width="23px"
                background="#707efa"
                center
              >
                <Span margin="-3px 0 0 0">✓</Span>
              </Flex>
              <Paragraph margin="1rem 0 0 0" lineHeight="130%" color="white">
                Unlimited data connections
              </Paragraph>
            </Div>
            <Div>
              <Flex
                borderRadius="50%"
                height="23px"
                width="23px"
                background="#707efa"
                center
              >
                <Span margin="-3px 0 0 0">✓</Span>
              </Flex>
              <Paragraph margin="1rem 0 0 0" lineHeight="130%" color="white">
                Priority support
              </Paragraph>
            </Div>
            <Div>
              <Flex
                borderRadius="50%"
                height="23px"
                width="23px"
                background="#707efa"
                center
              >
                <Span margin="-3px 0 0 0">✓</Span>
              </Flex>
              <Paragraph margin="1rem 0 0 0" lineHeight="130%" color="white">
                Tailored one-on-one training
              </Paragraph>
            </Div>
            <Div>
              <Flex
                borderRadius="50%"
                height="23px"
                width="23px"
                background="#707efa"
                center
              >
                <Span margin="-3px 0 0 0">✓</Span>
              </Flex>
              <Paragraph margin="1rem 0 0 0" lineHeight="130%" color="white">
                Up-to-the-minute data refresh
              </Paragraph>
            </Div>
          </Grid>
          <Paragraph center margin="2rem 0 0" color="white" fontSize="1rem">
            *Fair use limits apply.
          </Paragraph>
        </Flex>
      </Div>
      <Flex container gap="6rem">
        <Div maxWidth="900px" margin="8rem auto 2rem">
          <Heading center as="h2" margin="0 0 2rem">
            Why Klipfolio?
          </Heading>
          <Paragraph center>
            {`After several years of working with companies like yours, we’ve
            designed the Unlimited Company Plan to eliminate all of the most
            common roadblocks faced by large teams like yours.`}
          </Paragraph>
        </Div>
        <Grid columns="1fr 1fr" columnsSm="1fr" gap="4rem">
          <Flex gap="2rem">
            <Heading as="h3">Keep the bosses happy</Heading>
            <Paragraph fontSize="1rem">
              {`Is there anything that makes an executive team lead happier than a
              beautiful, actionable report delivered automatically to their
              inbox on a recurring basis? Our c-suite doesn’t think so.`}
            </Paragraph>
            <Paragraph fontSize="1rem">
              We will help you build, automate, schedule, and deliver the
              executive reports with the metrics your bosses have been dreaming
              of. We will also help you identify which metrics matter most to
              them and will have the biggest impact on their team deliverables
              and overall objectives.
            </Paragraph>
          </Flex>
          <Flex gap="2rem">
            <Heading as="h3">Get world-class support</Heading>
            <Paragraph fontSize="1rem">
              <b>You deserve to be bumped to the front of the line</b>. We
              understand the type of work that goes into launching a new
              reporting solution for large teams like yours, so we want to make
              your experience as smooth as possible.
            </Paragraph>
            <Paragraph fontSize="1rem">
              {`You will gain access to your own dedicated Klipfolio expert and
              experience shorter ticket response times. You’ve made dashboarding
              a priority for your team, so it’s only fair that we make you our
              priority.`}
            </Paragraph>
          </Flex>
          <Flex gap="2rem">
            <Heading as="h3">Maintain your data security</Heading>
            <Paragraph fontSize="1rem">
              The availability, confidentiality, and integrity of your data is
              of utmost importance to your business, and to us. Rest easy
              knowing we use multiple safeguards to protect your data, and are
              constantly monitoring and improving the security around our
              offerings.
            </Paragraph>
            <Paragraph fontSize="1rem">
              Your account will include ID and password encryption, HTTPs and
              SSL, and dashboard/reporting access controls which can be set by
              users with different privileges.
            </Paragraph>
          </Flex>
          <Flex gap="2rem">
            <Heading as="h3">Work without limitations</Heading>
            <Paragraph fontSize="1rem">
              Take the first year to get set up while having access to all the
              bells and whistles. We want you to be able to tailor your reports
              to your needs in every aspect. You have a vision, and we want
              nothing to stand in your way of achieving it.
            </Paragraph>
            <Paragraph fontSize="1rem">
              Add as many <b>users</b> as you need. Connect as many&nbsp;
              <b>data sources</b> as you need. Build as many&nbsp;
              <b>visualizations</b> for as many teams and departments as you
              need.
            </Paragraph>
          </Flex>
        </Grid>
        <Flex gap="2rem">
          <Heading center as="h3">
            Loyalty has its perks...
          </Heading>
          <Paragraph center>
            When it comes time to renew after your first year, we&apos;ll work
            with you on a flat annual fee.
          </Paragraph>
        </Flex>
        <Container text>
          <Form>
            <Heading id="contact-us" center as="h2" margin="0 0 1rem">
              No Hassles, No Surpises
            </Heading>
            <Paragraph center margin="0 0 4rem">
              The Unlimited Company Plan makes it easy for larger teams to
              experiment, grow and roll out a BI platform that works for
              everyone. We&apos;re here to answer any questions you have!
            </Paragraph>
            <HubspotForm
              portalId={"7477725"}
              formId={"a0788cf8-8d01-41f2-9b12-4eee8cf54a5e"}
            />
          </Form>
        </Container>
      </Flex>
      <Container>
        <Div maxWidth="900px" margin="8rem auto">
          <Heading center as="h3" margin="0 0 2rem">
            Frequently Asked Questions
          </Heading>
          <Flex gap="1rem">
            <Accordion title="What happens after the first year?">
              <Paragraph fontSize="1.1rem">
                When it comes time to renew, your dedicated Klipfolio Success
                Manager will work with you to assess your current usage and help
                you craft a custom plan to meet your specific needs. Our
                publically available plans are certainly an option as well.
              </Paragraph>
            </Accordion>
            <Accordion
              title={`What do the “setup and training services” really entail?`}
            >
              <Paragraph fontSize="1.1rem">
                {`We’ll get hands-on with you to make sure your reporting vision
                  becomes a reality. This includes helping to set up your users
                  and add integrations, assisting in building data sources,
                  supporting you in building your first dashboards and reports,
                  and more. Once you are up and running, we’ll facilitate as
                  many live training sessions as you need to make sure everyone
                  on your team understands how Klipfolio will work for them.
                  We’ll be there from day one, ready to answer any questions.`}
              </Paragraph>
            </Accordion>
            <Accordion title="Why would I choose this plan over plans?">
              <Paragraph fontSize="1.1rem">
                {`Our standard plans are self-service oriented. With the
                  Unlimited Company Plan, we will take care of the heavy lifting
                  on the front-end, leaving you and your team to focus on your
                  day-to-day activities. Also, the plan gives access to
                  everything in Klipfolio, without any limitations! Each time
                  you add a new user, more integrations, and more dashboards
                  there’s no extra cost.`}
              </Paragraph>
            </Accordion>
            <Accordion title="Can I switch to this plan if I'm a current customer?">
              <Paragraph fontSize="1.1rem">
                {`The Klipfolio Unlimited offer is intended for new direct customers, but we’d welcome the opportunity to discuss your account and explore a custom plan with you anytime. Please contact us at: `}
                &nbsp;
                <Anchor link={"mailto:success@klipfolio.com"}>
                  success@klipfolio.com
                </Anchor>
              </Paragraph>
            </Accordion>
          </Flex>
        </Div>
      </Container>
      <Div textContainer center margin="4rem auto 8rem">
        <Heading as="h2" fontSize="3rem" lineHeight="1.2" margin="0 0 2rem">
          Trusted by +50,000 data-driven leaders and their teams
        </Heading>
        <Paragraph fontSize="1.3rem">
          Smart businesses know that providing data insights drives their
          success!
        </Paragraph>
        <Grid margin="3rem 0 0" columns="repeat(auto-fill, minmax(150px, 1fr))">
          {users.map(({ node: user }, i) => {
            return (
              <UserIcon key={`usericon-${i}`}>
                <Image
                  objectFit="scale-down"
                  style={{ width: "100%" }}
                  file={user.company}
                />
              </UserIcon>
            )
          })}
        </Grid>
      </Div>
      <PricingIcons />
    </Layout>
  )
}

CompanyPlanPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query CompanyPlanQuery {
    users: allOurUsers {
      edges {
        node {
          company {
            title
            cdn
            placeholder
            directus_id
          }
        }
      }
    }
  }
`

export default CompanyPlanPage
